import { useEffect, useMemo, useState } from 'react'

import { useDisplayUserError } from '@yes.technology/react-toolkit'
import {
  CredentialItem,
  Credentials
} from 'authentication/shared/types/authentication.type'
import { useAuthentication } from 'authentication/shared/hooks'
import useUpgradeToken from 'authentication/shared/hooks/useUpgradeToken'

const formatCredentialsOptions = (
  optionsUuids: CredentialItem[] | null | undefined,
  withSID: boolean
) => {
  if (!optionsUuids) {
    return []
  }

  return optionsUuids.map(({ uuid, sid, des }) => ({
    id: uuid,
    value: uuid,
    label: withSID ? `${sid} |  ${des}` : des
  }))
}

const useCredentials = () => {
  const { authenticatedData, startLoading, stopLoading } = useAuthentication()

  const upgradeToken = useUpgradeToken()

  const { displayUserError } = useDisplayUserError()

  const credentials = useMemo(() => {
    const available_credentials = authenticatedData?.available_credentials
    if (!available_credentials) return null

    return {
      applicationSystem: {
        options: formatCredentialsOptions(
          available_credentials.applicationsystems_info,
          false
        )
      },
      organization: {
        options: formatCredentialsOptions(
          available_credentials.organizations_info,
          true
        )
      },
      user: {
        options: formatCredentialsOptions(available_credentials.user_info, true)
      }
    }
  }, [authenticatedData])

  const [selectedCredentials, setSelectedCredentials] = useState<Credentials>({
    applicationsystem_uuid: '',
    organization_uuid: '',
    user_uuid: ''
  })

  useEffect(() => {
    if (!credentials) return

    setSelectedCredentials({
      applicationsystem_uuid: credentials.applicationSystem.options[0].id,
      organization_uuid: credentials.organization.options[0].id,
      user_uuid: credentials.user.options[0].id
    })
  }, [credentials])

  const submitCredentials = async (
    onSuccess: () => void,
    onError: (error?: unknown) => void
  ) => {
    startLoading()

    try {
      await upgradeToken(selectedCredentials)
      onSuccess?.()
    } catch (error) {
      onError?.(error)
      displayUserError({})
      console.error(error)
    } finally {
      stopLoading()
    }
  }

  return {
    credentials,
    selectedCredentials,
    setSelectedCredentials,
    submitCredentials
  }
}

export default useCredentials
