import React, { useState } from 'react'
import { useTranslation } from 'react-i18n-lite'

import { useDocumentTitle } from 'infrastructure/shared/hooks'

import {
  useAuthentication,
  useRedirectOrigin
} from 'authentication/shared/hooks'

import {
  Button,
  DataFieldSelection,
  feedbackcard
} from '@yes.technology/react-toolkit'
import SuggestionChangePassword from 'authentication/Login/LoginContainer/SuggestionChangePassword'
import AuthenticationTemplate from 'authentication/shared/components/AuthenticationTemplate'
import usePasswordChangeSuggestion from 'authentication/shared/hooks/usePasswordChangeSuggestion'
import { CredentialOptions } from 'authentication/shared/types'
import useCredentials from './useCredentials'

const Credentials = () => {
  const { t } = useTranslation()

  const [shouldDisableSubmit, setShouldDisableSubmit] = useState(false)

  const { redirectToOrigin } = useRedirectOrigin()

  const { authenticatedData } = useAuthentication()

  const { shouldOpenPasswordChange, handleSuggestPasswordChange } =
    usePasswordChangeSuggestion()

  const {
    credentials,
    submitCredentials,
    setSelectedCredentials,
    selectedCredentials
  } = useCredentials()

  const getOptionSetter = (credentialId: string) => {
    return (option: CredentialOptions[]) => {
      setSelectedCredentials((oldCredentials) => ({
        ...oldCredentials,
        [credentialId]: option[0].id
      }))
    }
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    setShouldDisableSubmit(true)

    const onSuccess = async () => {
      feedbackcard(t('credentials.feedback.success.title'), {
        message: t('credentials.feedback.success.msg'),
        type: 'success'
      })

      const isRedirectToOrigin = !handleSuggestPasswordChange(authenticatedData)

      if (isRedirectToOrigin) {
        redirectToOrigin()
      } else {
        setShouldDisableSubmit(false)
      }
    }

    const onError = () => setShouldDisableSubmit(false)

    await submitCredentials(onSuccess, onError)
  }

  useDocumentTitle({ title: t('credentials.title') })

  return (
    <AuthenticationTemplate>
      <h2>{t('credentials.title')}</h2>

      {credentials && (
        <form
          style={{ display: 'flex', flexDirection: 'column', gap: 8 }}
          onSubmit={handleSubmit}
        >
          <DataFieldSelection
            label={t('active-session.ecosystem-credentials')}
            options={[
              {
                id: 'Yes Ecosystem',
                value: 'Yes Ecosystem',
                label: 'Yes Ecosystem'
              }
            ]}
            selected={[
              {
                id: 'Yes Ecosystem',
                value: 'Yes Ecosystem',
                label: 'Yes Ecosystem'
              }
            ]}
            onChange={getOptionSetter('')}
            required
          />
          <DataFieldSelection
            label={t('active-session.applicationsystem-credential')}
            options={credentials.applicationSystem.options}
            selected={credentials.applicationSystem.options.filter(
              (option) =>
                option.id === selectedCredentials.applicationsystem_uuid
            )}
            onChange={getOptionSetter('applicationsystem_uuid')}
            required
          />
          <DataFieldSelection
            label={t('active-session.organization-credential')}
            options={credentials.organization.options}
            selected={credentials.organization.options.filter(
              (option) => option.id === selectedCredentials.organization_uuid
            )}
            onChange={getOptionSetter('organization_uuid')}
            required
          />
          <DataFieldSelection
            label={t('active-session.user-credential')}
            options={credentials.user.options}
            selected={credentials.user.options.filter(
              (option) => option.id === selectedCredentials.user_uuid
            )}
            onChange={getOptionSetter('user_uuid')}
            required
          />
          <Button
            disabled={shouldDisableSubmit}
            impactClass='workflow'
            type='submit'
          >
            {t('credentials.apply')}
          </Button>
        </form>
      )}
      <SuggestionChangePassword isOpen={shouldOpenPasswordChange} />
    </AuthenticationTemplate>
  )
}

export default Credentials
